import {
  API_URL,
  FLOW_API_URL,
  FUNCTIONS_API_URL,
  OMR_FUNCTIONS_API_URL,
  getAuthToken
} from 'config/settings';
import queryString from 'query-string';
import Client from './Client';
import { getUserRole } from 'helpers/getUserRole';

const getFlowApiUri = uri => getUri(FLOW_API_URL, uri);

const getFunctionsApiUri = uri => getUri(FUNCTIONS_API_URL, `/api/${uri}`);

const getOmrFunctionsApiUri = uri =>
  getUri(OMR_FUNCTIONS_API_URL, `/api/${uri}`);

const getApiUri = uri => getUri(API_URL, uri);

const getUri = (endpoint = null, uri = null) => {
  const formattedUri = `/${uri}`.replace(/\/+/g, '/');
  return `${endpoint}${formattedUri}`;
};

class Api {
  static async signIn(username, password) {
    const params = {
      username,
      password
    };

    return Client.post(getApiUri('v3/tokens/refresh'), params);
  }

  static async signInRedirect(username, password) {
    return Client.post(getApiUri('v3/tokens/login-redirect'), {
      username,
      password
    });
  }

  static async tokenRedirect(token, redirectUri = null) {
    const params = {
      token
    };
    if (redirectUri !== null) {
      params.redirectUri = redirectUri;
    }
    const queryParams = queryString.stringify(params);
    return Client.post(getApiUri(`v3/tokens/token-redirect?${queryParams}`));
  }

  static async googleTokenRedirect(token, redirectUri = null) {
    const params = {
      token
    };
    if (redirectUri !== null) {
      params.redirectUri = redirectUri;
    }
    const queryParams = queryString.stringify(params);
    return Client.post(
      getApiUri(`v3/tokens/google-token-redirect?${queryParams}`)
    );
  }

  static async getUserRoleContext(
    schoolId = null,
    includes = ['groups', 'teachers', 'feature-flags', 'user-state']
  ) {
    const token = getAuthToken();

    if (!token) return null;

    const userRole = getUserRole(token);

    if (userRole === 'Student') return this.getStudentContext();

    if (userRole === 'Parent') return this.getParentContext();

    let queryParams = queryString.stringify({ includes });

    if (schoolId) {
      queryParams += `${queryParams?.length ? '&' : ''}schoolId=${schoolId}`;
    }

    return Client.get(getApiUri(`v4/users/me?${queryParams}`));
  }

  static async getUserContext(includes = []) {
    return Client.get(
      getApiUri(`v4/users/me?${queryString.stringify({ includes })}`)
    );
  }

  static async getParentContext(
    includes = ['children', 'students', 'user-preferences', 'feature-flags']
  ) {
    return await this.getUserContext(includes);
  }

  static async getStudentContext(
    includes = [
      'students',
      'user-state',
      'user-preferences',
      'feature-flags',
      'achievements',
      'routine-state',
      'user-items',
      'transaction-insights',
      'notifications'
    ]
  ) {
    return await this.getUserContext(includes);
  }

  static async updateUser(userId, userUpdateDto) {
    return Client.put(getApiUri(`v3/users/${userId}`), userUpdateDto);
  }

  static async passwordReset(userName) {
    return Client.post(getApiUri('v3/password-resets'), {
      userName,
      targetSite: 'EediTeacher'
    });
  }

  static async resetPasswordWithInvitationCode(invitationCode, password) {
    return Client.put(getApiUri(`v3/invitations/${invitationCode}/users`), {
      password
    });
  }

  static async signUp(user) {
    return Client.post(getApiUri('v3/users'), user);
  }

  static async signUpWithCode(code, user) {
    return Client.put(getApiUri(`v3/invitations/${code}/users`), user);
  }

  static async signOut() {}

  static async getAccessToken(refreshToken) {
    return Client.get(
      getApiUri(`v3/tokens/access?refreshToken=${refreshToken}`)
    );
  }

  static async signInWithGoogle(googleToken) {
    const params = {
      googleToken
    };
    return Client.get(getApiUri(`v3/GoogleTokenSignIn`), params);
  }

  static async acceptStudentInvitationCode(invitationCode) {
    return Client.post(getApiUri(`v3/invitations/${invitationCode}/students`));
  }

  static async acceptTeacherInvitationCode(invitationCode) {
    return Client.post(getApiUri(`v3/invitations/${invitationCode}/teachers`));
  }

  static async invitationCodeProfile(invitationCode) {
    return await Client.get(getApiUri(`v3/invitations/${invitationCode}`));
  }

  static async addSchool(fields) {
    const { name, address, city, country, zipOrPostCode } = fields;
    return await Client.post(getApiUri(`v3/schools`), {
      name,
      addressLine1: address,
      city,
      country,
      zipOrPostCode
    });
  }

  static async addCleverSchool(fields) {
    const { name, address, city, country, zipOrPostCode } = fields;
    return await Client.post(getApiUri(`v3/schools/clever`), {
      name,
      addressLine1: address,
      city,
      country,
      zipOrPostCode
    });
  }

  static async joinSchool(schoolId, userId) {
    return await Client.post(getApiUri(`v3/schools/${schoolId}/teachers`), {
      isMakeDefault: true,
      userId
    });
  }

  static async addTeacherToSchool(schoolId, body) {
    return await Client.post(
      getApiUri(`v3/schools/${schoolId}/teachers`),
      body
    );
  }

  static async uploadUserCollection(users, isSendEmails = false) {
    return Client.post(
      getApiUri(`v3/user-collections?isSendEmails=${isSendEmails}`),
      users
    );
  }

  static async uploadStudentCollection(schoolId, students) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/student-collections`),
      students
    );
  }

  static async getRedirectToken() {
    return Client.get(getApiUri('v3/tokens/redirect'), null);
  }

  static async sendTeacherGeneratedGroupNotification(
    teacherId,
    groupId,
    notification
  ) {
    return Client.post(
      getApiUri(
        `v3/teachers/${teacherId}/groups/${groupId}/notifications/send-notifications`
      ),
      notification
    );
  }

  static async updateTeacherGroups(teacherId, groupIds) {
    return Client.put(getApiUri(`v3/teachers/${teacherId}`), {
      groupIds
    });
  }

  static async removeTeacherFromGroup(teacherId, groupId) {
    return Client.delete(
      getApiUri(`v3/teachers/${teacherId}/groups/${groupId}`)
    );
  }

  static async _updateGroup(groupId, body = {}) {
    return Client.put(getApiUri(`v3/groups/${groupId}`), body);
  }

  static async updateGroup(groupId, body = {}) {
    return Client.put(getApiUri(`v5/groups/${groupId}`), body);
  }

  static async deleteGroup(groupId) {
    return Client.delete(getApiUri(`v3/groups/${groupId}`));
  }

  static async getGroup(groupId, includes = []) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(getApiUri(`v3/groups/${groupId}?${queryParams}`));
  }

  static async getTeachersGroup(
    teacherId,
    groupId,
    includes = ['students', 'family-insights']
  ) {
    return Client.get(getApiUri(`v3/teachers/${teacherId}/groups/${groupId}`), {
      includes
    });
  }

  static async validateTeacherGeneratedGroupNotification(
    teacherId,
    groupId,
    notification
  ) {
    return Client.post(
      getApiUri(
        `v3/teachers/${teacherId}/groups/${groupId}/notifications/validate-notifications`
      ),
      notification
    );
  }

  static async getAssignment(assignmentId) {
    return Client.get(getApiUri(`v3/assignments/${assignmentId}`));
  }

  static async deleteAssignment(assignmentId) {
    return Client.delete(getApiUri(`v3/assignments/${assignmentId}`));
  }

  static async updateAssignment(assignmentId, assignment) {
    return Client.put(getApiUri(`v5/assignments/${assignmentId}`), assignment);
  }

  static async _setAssignment(quizId, assignmentInsert) {
    return Client.post(
      getApiUri(`v3/quizzes/${quizId}/assignments`),
      assignmentInsert
    );
  }

  static async setAssignment(quizId, assignmentInsert) {
    return Client.post(
      getApiUri(`v5/quizzes/${quizId}/assignments`),
      assignmentInsert
    );
  }

  static async getTeacherGroups(teacherId, includes = []) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(
      getApiUri(`v3/teachers/${teacherId}/groups?${queryParams}`)
    );
  }

  /*
    Quiz API
   */
  static async getQuiz(quizId) {
    return Client.get(getApiUri(`v3/quizzes/${quizId}`));
  }

  static async getTopicPathwayQuizFromShortCode(
    shortCode,
    includes = ['quiz']
  ) {
    return Client.get(
      getApiUri(
        `v4/topic-pathway-quizs/shortcode/${shortCode}?${queryString.stringify({
          includes
        })}`
      )
    );
  }

  static async updateQuiz(quizId, quizUpdateDto) {
    return Client.put(getApiUri(`v3/quizzes/${quizId}`), quizUpdateDto);
  }

  static async cloneQuiz(quizId) {
    const params = [quizId];

    return Client.post(getApiUri('v3/quizzes/clone'), params);
  }

  static async getQuizInsights(quizId) {
    return Client.get(getApiUri(`v3/quizzes/${quizId}/quiz-insights`));
  }

  static async startAssignment(assignmentId) {
    return Client.post(
      getApiUri(`v3/assignments/${assignmentId}/quiz-sessions`)
    );
  }

  static async startQuiz(quizId) {
    return Client.post(getApiUri(`v3/quizzes/${quizId}/quiz-sessions`));
  }

  static async resumeQuiz(quizSessionId) {
    return Client.get(getApiUri(`v3/quiz-sessions/${quizSessionId}`));
  }

  static async answerQuestion(quizSessionId, answer, isRetry = false) {
    const answerType = isRetry ? 'corrections' : 'answers';
    const params = {
      [answerType]: [answer]
    };

    return Client.put(getApiUri(`v3/quiz-sessions/${quizSessionId}`), params);
  }

  static async reviewedQuestion(quizSessionId, questionId) {
    const params = {
      reviewedQuestions: [
        {
          questionId
        }
      ]
    };
    return Client.put(getApiUri(`v3/quiz-sessions/${quizSessionId}`), params);
  }

  static async getQuestion(questionId) {
    return Client.get(getApiUri(`v3/questions/${questionId}`));
  }

  static async getQuestionResources(questionId) {
    return Client.get(getApiUri(`v3/questions/${questionId}/resources`));
  }

  static async getQuizReview(quizSessionId) {
    return Client.get(getApiUri(`v3/quiz-sessions/${quizSessionId}`));
  }

  static async getQuizQuestions(quizId, includes = ['subjects']) {
    return Client.get(getApiUri(`v3/quizzes/${quizId}/questions`), {
      includes
    });
  }

  static async getQuestionSheetPdf(assignmentId) {
    return Client.get(
      getOmrFunctionsApiUri('v1/omr/question-sheet-generator'),
      { assignmentId },
      {
        readBodyAs: 'file'
      }
    );
  }

  static async getBubbleSheetPdf(assignmentId) {
    return Client.get(
      getOmrFunctionsApiUri('v1/omr/bubblesheet-generator'),
      {
        assignmentId
      },
      {
        readBodyAs: 'file'
      }
    );
  }

  /*
    Classes API
   */
  static async joinClass(code) {
    return Client.post(getApiUri(`v3/invitations/${code}/students`));
  }

  static async getGroupCountryYearGroup(groupId) {
    return Client.get(getApiUri(`v3/groups/${groupId}/country-year-group`));
  }

  static async getStudentCountryYearGroup(studentId, schoolId) {
    return Client.get(
      getApiUri(
        `v3/schools/${schoolId}/students/${studentId}/country-year-group`
      )
    );
  }

  static async createYearGroup(schoolId, yearGroupName) {
    return Client.post(getApiUri(`v3/schools/${schoolId}/year-groups`), {
      name: yearGroupName
    });
  }

  static async createDepartment(schoolId, departmentInsertDTO) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/departments`),
      departmentInsertDTO
    );
  }

  static async createGroup(schoolId, body) {
    return Client.post(getApiUri(`v3/schools/${schoolId}/groups`), body);
  }

  static async getGroupStudents(groupId, includes = null) {
    return Client.get(getApiUri(`v3/groups/${groupId}/students`), { includes });
  }

  static async getGroupTeachers(groupId) {
    return Client.get(getApiUri(`v3/groups/${groupId}/teachers`));
  }

  static async getStudentClasses(studentId) {
    return Client.get(getApiUri(`v3/students/${studentId}/groups`));
  }

  static async getTeacherClasses({
    teacherId,
    includes = null,
    statisticsPeriodInHours = 0,
    searchText = null,
    page = 1,
    limit = 20,
    sort = []
  }) {
    let query = Object.assign(
      {
        'request.filter.includes': includes,
        'request.filter.statisticsPeriodInHours': statisticsPeriodInHours,
        'request.skip': (page - 1) * limit,
        'request.take': limit
      },
      this.parseSort(sort)
    );

    if (searchText) {
      query = { ...query, 'request.filter.searchText': searchText };
    }

    return Client.get(getApiUri(`v4/teachers/${teacherId}/groups`), query);
  }

  static async getSchool(schoolId) {
    return Client.get(getApiUri(`v3/schools/${schoolId}`));
  }

  static async updateSchool(schoolId, body = {}) {
    return Client.put(getApiUri(`v3/schools/${schoolId}`), body);
  }

  static async searchSchools(searchText = '', page = 1, limit = 50) {
    const query = Object.assign({
      'filter.searchText': searchText,
      skip: (page - 1) * limit,
      take: limit
    });

    return Client.get(getApiUri(`v3/schools`), query);
  }

  static async getSchools(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/students?includes=school`));
  }

  static async getChildren(userId, includes = []) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(getApiUri(`v3/users/${userId}/children?${queryParams}`));
  }

  static async getStudentAssignmentInsightsForAssignment(
    studentId,
    assignmentId,
    includes = []
  ) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(
      getApiUri(
        `v3/students/${studentId}/assignments/${assignmentId}/assignment-insights?${queryParams}`
      )
    );
  }

  static async getTeacherAssignmentInsightsForAssignment(
    teacherId,
    assignmentId,
    includes = []
  ) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(
      getApiUri(
        `v3/teachers/${teacherId}/assignments/${assignmentId}/assignment-insights?${queryParams}`
      )
    );
  }

  /*
    Students API
  */

  static async updateStudent(studentId, studentUpdateDTO) {
    return Client.put(getApiUri(`v3/students/${studentId}`), studentUpdateDTO);
  }

  static async updateEediFamilyStudentUser(userId, studentUserId, body = {}) {
    return Client.put(
      getApiUri(`v3/users/${userId}/eedi-plus/student-users/${studentUserId}`),
      body
    );
  }

  static async getStudent(studentId, includes = []) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(getApiUri(`v3/students/${studentId}?${queryParams}`));
  }

  /*
    Teachers API
   */

  static async updateTeacher(teacherId, teacherUpdateDTO) {
    return Client.put(getApiUri(`v3/teachers/${teacherId}`), teacherUpdateDTO);
  }

  /* Retrieval Practice */
  static async getGroupRetrievalPractice(groupId) {
    return Client.get(
      getApiUri(`v3/groups/${groupId}/retrieval-practice-assignments`)
    );
  }

  static async getRetrievalPracticeRecommendations(teacherId, groupId) {
    return Client.get(
      getApiUri(
        `v3/teachers/${teacherId}/groups/${groupId}/retrieval-practice-recommendations`
      )
    );
  }

  static async upsertTeacherGroupRetrievalPractice(
    teacherId,
    groupId,
    retrievalPracticeAssignmentUpsertDTO
  ) {
    return Client.put(
      getApiUri(
        `v3/teachers/${teacherId}/groups/${groupId}/retrieval-practice-assignments`
      ),
      retrievalPracticeAssignmentUpsertDTO
    );
  }

  static async getRetrievalPracticeGroupInsights(teacherId, groupId) {
    return Client.get(
      getApiUri(
        `v3/teachers/${teacherId}/groups/${groupId}/retrieval-practice-assignment-insights`
      )
    );
  }

  static async deleteRetrievalPracticeAssignment(
    teacherId,
    groupId,
    scheduleWeekNumber
  ) {
    return Client.delete(
      getApiUri(
        `v5/teachers/${teacherId}/groups/${groupId}/retrieval-practice-assignments/${scheduleWeekNumber}`
      )
    );
  }

  /* Insights */

  static async getTeacherAssignmentInsights(
    teacherId,
    status,
    groupId = null,
    page = 1,
    limit = 30,
    includes = [],
    sort = []
  ) {
    const query = Object.assign(
      {
        'request.filter.assignmentGroup': status,
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes
      },
      this.parseSort(sort)
    );

    if (groupId) {
      query['request.filter.groupIds'] = groupId;
    }

    return Client.get(
      getApiUri(`v3/teachers/${teacherId}/assignment-insights`),
      query
    );
  }

  static async getIndependentStudyInsights({
    schoolId,
    quizId,
    includes = []
  }) {
    return Client.get(
      getApiUri(`v3/schools/${schoolId}/quiz/${quizId}/topic-insights`),
      {
        includes
      }
    );
  }

  static async getStudentIndependentStudyInsights({
    schoolId,
    studentId,
    quizId
  }) {
    return Client.get(
      getApiUri(
        `v3/schools/${schoolId}/students/${studentId}/quiz/${quizId}/topic-insights`
      )
    );
  }

  static async getTeacherAssignmentInsightsForGroup(
    teacherId,
    groupId,
    page = 1,
    limit = 30,
    includes = [],
    assignmentGroup = 'Done'
  ) {
    let query = {
      'request.skip': (page - 1) * limit,
      'request.take': limit,
      //'request.sort[0].FieldName': 'RelevantDate',
      //'request.sort[0].Direction': status === 'Upcoming' ? 'Asc' : 'Desc',
      'request.filter.includes': includes,
      'request.filter.assignmentGroup': assignmentGroup
    };
    return Client.get(
      getApiUri(
        `v3/teachers/${teacherId}/groups/${groupId}/assignment-insights`
      ),
      query
    );
  }

  static async getStudentAssignmentInsights(
    studentId,
    status,
    page = 1,
    limit = 30,
    includes = [],
    sort = []
  ) {
    const query = Object.assign(
      {
        'request.filter.assignmentGroup': status,
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes
      },
      this.parseSort(sort)
    );

    return Client.get(
      getApiUri(`v3/students/${studentId}/assignment-insights`),
      query
    );
  }

  static async getStudentTopicInsights({
    schoolId,
    studentId,
    page = 0,
    limit = 30,
    sort = [],
    includes = []
  }) {
    const query = Object.assign(
      {
        'request.skip': page * limit,
        'request.take': limit,
        'request.filter.includes': includes
      },
      this.parseSort(sort)
    );

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/students/${studentId}/topic-insights`),
      query
    );
  }

  static async getGroupStudentTopicInsights({
    schoolId,
    groupId,
    page = 1,
    limit = 10000,
    sort = [],
    includes = []
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes
      },
      this.parseSort(sort)
    );

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/groups/${groupId}/topic-insights`),
      query
    );
  }

  static async getTopicInsights({
    schoolId,
    page = 1,
    limit = 10000,
    sort = [],
    includes = []
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes
      },
      this.parseSort(sort)
    );

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/topic-insights`),
      query
    );
  }

  static async getGroupTopicInsights({
    schoolId,
    page = 1,
    limit = 10000,
    sort = [],
    searchTerm = '',
    includes = [],
    groupIds = []
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes,
        'request.filter.searchtext': searchTerm,
        'request.filter.groupIds': groupIds
      },
      this.parseSort(sort)
    );

    return Client.get(
      getApiUri(`v3/school/${schoolId}/group-topic-insights`),
      query
    );
  }

  static async getStudentAnalytics({ schoolId, studentId }) {
    const includes = ['activities', 'performance', 'inter-school-performance'];

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/students/${studentId}/analytics`),
      { 'request.filter.includes': includes }
    );
  }

  static async getGroupAnalytics({ schoolId, groupId }) {
    const includes = ['activities', 'performance', 'inter-school-performance'];

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/groups/${groupId}/analytics`),
      { 'request.filter.includes': includes }
    );
  }

  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string[]} [param.includes=[]] - data to include in the request
   * @param {number} [param.page=1] - which page of data to return
   * @param {number} [param.limit=10000] - size of pages to split data into
   * @param {object[]} [param.sort=[]] - array of parameters to define sort order before paging
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.groupIds=[]] - filters results to students in listed groups. Empty array is the same as all groups
   * @param {('active'|'archived'|'all')} [param.archiveStatus='active'] - filters students to the specified activity level
   */
  static async getAllStudentsInSchool({
    schoolId,
    includes = [],
    page = 1,
    limit = 10000,
    sort = [],
    searchTerm = '',
    groupIds = [],
    archiveStatus = 'active'
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes,
        'request.filter.search': searchTerm,
        'request.filter.groupIds': groupIds,
        'request.filter.archiveStatus': archiveStatus
      },
      this.parseSort(sort)
    );

    return Client.get(getApiUri(`v3/schools/${schoolId}/students`), query);
  }

  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.groupIds=[]] - filters results to students in listed groups. Empty array is the same as all groups
   */
  static async getAllStudentsInSchoolCsv({
    schoolId,
    searchTerm = '',
    groupIds = []
  }) {
    const query = Object.assign({
      'filter.search': searchTerm,
      'filter.groupIds': groupIds
    });

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/students/export`),
      query,
      { headers: { Accept: 'text/csv' }, readBodyAs: 'file' }
    );
  }

  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.groupIds=[]] - filters results to students in listed groups. Empty array is the same as all groups
   */
  static async getAllStudentsInSchoolPdf({
    schoolId,
    searchTerm = '',
    groupIds = []
  }) {
    const query = Object.assign({
      'filter.search': searchTerm,
      'filter.groupIds': groupIds
    });

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/students/export/pdf`),
      query,
      { headers: { Accept: 'application/pdf' }, readBodyAs: 'file' }
    );
  }

  static async deleteStudentsFromSchool(schoolId, studentIds = []) {
    const studentDeleteDTO = {
      schoolId,
      studentIds
    };

    return Client.delete(
      getApiUri(`v3/schools/${schoolId}/students`),
      studentDeleteDTO
    );
  }

  static async getAllGroupsInSchool(schoolId, includes = []) {
    const queryParams = queryString.stringify({ includes });

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/groups?${queryParams}`)
    );
  }

  static async validateStudentImports(schoolId, students = []) {
    return Client.post(
      getApiUri(`/v3/schools/${schoolId}/students/validate-import`),
      {
        students
      }
    );
  }

  /*
  Teacher Management API
  */
  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string[]} [param.includes=[]] - data to include in the request
   * @param {number} [param.page=1] - which page of data to return
   * @param {number} [param.limit=10000] - size of pages to split data into
   * @param {object[]} [param.sort=[]] - array of parameters to define sort order before paging
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.group=[]] - filters results to students in listed groups. Empty array is the same as all groups
   * @param {('all'|'pending'|'confirmed')} [param.membershipStatus='all'] - filters teachers based on school membership status
   */
  static async getAllTeachersInSchool({
    schoolId,
    includes = [],
    page = 1,
    limit = 10000,
    sort = [],
    searchTerm = '',
    groupIds = [],
    membershipStatus = 'all'
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes,
        'request.filter.search': searchTerm,
        'request.filter.groupIds': groupIds,
        'request.filter.schoolMembershipStatus': membershipStatus
      },
      this.parseSort(sort)
    );

    return Client.get(getApiUri(`v3/schools/${schoolId}/teachers`), query);
  }

  static async removeTeachersFromSchool(schoolId, teacherIds) {
    const params = {
      teacherIds
    };

    return Client.delete(
      getApiUri(`v3/schools/${schoolId}/teachers/remove-collection`),
      params
    );
  }

  static async uploadTeacherCollection(schoolId, teachers) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/teacher-collections`),
      teachers
    );
  }

  static async verifyTeacher(teacherId) {
    return Client.put(getApiUri(`v3/teachers/${teacherId}`), {});
  }

  static async deleteTeacher(teacherId) {
    return Client.delete(getApiUri(`v3/teachers/${teacherId}`));
  }

  static async getUsersTeachers(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/teachers?includes=school`));
  }

  /*
    Archive API
  */
  static async archiveStudents(schoolId, studentIds) {
    const params = {
      studentIds
    };

    return Client.put(
      getApiUri(`v3/schools/${schoolId}/students/archive-collection`),
      params
    );
  }

  static async unArchiveStudents(schoolId, studentIds) {
    const params = {
      studentIds
    };

    return Client.put(
      getApiUri(`v3/schools/${schoolId}/students/un-archive-collection`),
      params
    );
  }

  /*
    Assignments API
   */
  static async getNotifications(
    userId,
    status,
    schoolId,
    groupId = null,
    page = 1,
    limit = 30
  ) {
    let query = {
      'request.filter.notificationGroup': status,
      'request.filter.notificationCategory': 'Assignment',
      'request.skip': (page - 1) * limit,
      'request.take': limit,
      'request.filter.schoolId': schoolId,
      'request.sort[0].FieldName': 'RelevantDate',
      'request.sort[0].Direction': status === 'Upcoming' ? 'Asc' : 'Desc'
    };

    if (groupId) {
      query['request.filter.groupIds'] = groupId;
    }

    return Client.get(getApiUri(`v3/users/${userId}/notifications`), query);
  }

  static async dismissNotification(id) {
    return Client.put(getApiUri(`v3/notifications/${id}`));
  }

  /*
    Schemes
  */
  static async getSchemes(studentId) {
    return Client.get(
      getApiUri(`v3/students/${studentId}/scheme-assignments`),
      {
        includes: ['statistics', 'scheme-module']
      }
    );
  }

  static async getSchemeAssignments(studentId, schemeAssignmentId) {
    return Client.get(
      getApiUri(
        `v3/students/${studentId}/scheme-assignments/${schemeAssignmentId}/assignment-insights`
      ),
      {
        includes: ['statistics', 'quiz-session']
      }
    );
  }

  /*
    Feedback API
   */
  static async getFeedback(studentId, status = 'All', type = 'All') {
    let query = {
      'request.filter.status': status,
      'request.filter.type': type,
      'request.skip': 0,
      'request.take': 1000
    };

    return Client.get(
      getApiUri(`v3/students/${studentId}/answer-feedbacks`),
      query
    );
  }

  static async getAssignmentFeedbackQuestions(assignmentId) {
    return Client.get(
      getApiUri(`v3/assignments/${assignmentId}/feedback-questions`)
    );
  }

  static async updateFeedback(answerFeedbackId, params) {
    return Client.put(
      getApiUri(`v3/answer-feedbacks/${answerFeedbackId}`),
      params
    );
  }

  static async getMisconceptions() {
    const subjectId = 3;
    return Client.get(getApiUri(`v3/subjects/${subjectId}/misconceptions`));
  }

  static async getMisconceptionsQuestions(subjectId) {
    return Client.get(
      getApiUri(`v3/subjects/${subjectId}/misconceptions/questions`)
    );
  }

  static async updateAnswer(answer) {
    return Client.post(getApiUri(`v3/answers`), [answer]);
  }

  static async getInvitation(code) {
    return Client.get(getApiUri(`v3/invitations/${code}`));
  }

  /**
   * Dynamic Quiz
   */

  static async createDynamicQuizSession(userId) {
    return Client.post(getApiUri(`v3/dynamic-quiz-sessions/${userId}`));
  }

  static async updateDynamicQuizSession(quizSessionId, quizSessionUpdateDto) {
    return Client.put(
      getApiUri(`v3/dynamic-quiz-sessions/${quizSessionId}`),
      quizSessionUpdateDto
    );
  }

  static async generateDynamicQuizReport(schoolId, studentId) {
    return Client.get(
      getApiUri(
        `v3/schools/${schoolId}/students/${studentId}/dynamic-quiz-report`
      )
    );
  }

  /*
    Notifications API - This uses Azure Functions API
   */
  static async setPushNotifications(userId, allowPushNotifications) {
    const params = {
      userId,
      allowPushNotifications
    };

    return Client.put(getFunctionsApiUri(`v3/userpreferences`), params);
  }

  static async addDevice(deviceToken) {
    return Client.post(
      getFunctionsApiUri(`v3/push-notifications/devices/${deviceToken}`)
    );
  }

  static async getUserPreferences(userId) {
    return Client.get(
      getFunctionsApiUri(`v3/users/${userId}/user-preferences`)
    );
  }

  static async setUserPreferences(params) {
    return Client.put(getApiUri(`v3/user-preferences`), params);
  }

  /*
    Client Messages API
    Note - this uses the DQ functions v2 API.
    dev - https://diagnosticquestions-functions-dev.azurewebsites.net
    qa - https://diagnosticquestions-functions-qa.azurewebsites.net
    prod - https://diagnosticquestions-functions-prod.azurewebsites.net
  */

  static async getAllTopicPathwayCollections(
    includes = [],
    isPublished = true
  ) {
    const queryParams = queryString.stringify({ includes, isPublished });
    return Client.get(
      getApiUri(`/v4/topic-pathway-collections?${queryParams}`)
    );
  }

  static async getCollectionsView(
    collectionId,
    isCollectionGroupsIncluded = true,
    isIncludeQuestions = true,
    topicUnitId = null
  ) {
    const queryParams = queryString.stringify({
      isCollectionGroupsIncluded,
      isIncludeQuestions,
      topicUnitId
    });
    return Client.get(
      getApiUri(
        `v3/collections/collections-page/${collectionId}?${queryParams}`
      )
    );
  }

  static async searchTopicPathwayCollectionQuiz(
    {
      searchText = '',
      topicPathwayCollectionIds = [],
      yearGroupId = null,
      subjectIds = [],
      topicSearchText = null,
      sortFilter = null,
      includeNoInterventionTopics = true,
      skip = 0,
      take = 999,
      locale = null,
      topicTagIds = [],
      parentTopicTagIds = []
    },
    { signal }
  ) {
    let query = {
      'request.filter.searchText': searchText,
      'request.filter.subjectIds': subjectIds ? subjectIds : [],
      'request.filter.topicSearchText': topicSearchText,
      'request.filter.yearGroupId': yearGroupId,
      'request.filter.includeNoInterventionTopics': includeNoInterventionTopics,
      'request.filter.locale': locale,
      'request.filter.topicTagIds': topicTagIds,
      'request.filter.parentTopicTagIds': parentTopicTagIds,
      'request.skip': skip,
      'request.take': take
    };

    if (topicPathwayCollectionIds.length > 1) {
      topicPathwayCollectionIds.forEach((id, index) => {
        query[`request.filter.topicPathwayCollectionIds[${index}]`] = id;
      });
    } else if (topicPathwayCollectionIds.length === 1) {
      query['request.filter.topicPathwayCollectionIds'] =
        topicPathwayCollectionIds[0];
    }

    if (sortFilter) {
      query = {
        ...query,
        'request.sort[0].FieldName': sortFilter,
        'request.sort[0].Direction': 'Asc' // Our current UI doesn't seem to do sort directions?}
      };

      if (sortFilter === 'Level') {
        // For sorting with Level, we want to subsequently sort the result by they level sequence
        query = {
          ...query,
          'request.sort[1].FieldName': 'Sequence',
          'request.sort[1].Direction': 'Asc' // Our current UI doesn't seem to do sort directions?}
        };
      }
    }

    const params = queryString.stringify(query);

    const request = Client.get(
      getApiUri(`v4/topic-pathway-collections/search?${params}`),
      undefined,
      {
        headers: {
          cache: 'no-cache',
          pragma: 'no-cache'
        },
        signal
      }
    );

    return request;
  }

  static async getTopicTags(
    locale = null,
    parentId = null,
    topicPathwayCollectionIds = []
  ) {
    let queryParams = [];

    if (parentId) {
      queryParams.push(`parentId=${parentId}`);
    }

    if (locale) {
      queryParams.push(`locale=${locale}`);
    }

    if (topicPathwayCollectionIds.length > 0) {
      topicPathwayCollectionIds.forEach((id, index) => {
        queryParams.push(`topicPathwayCollectionIds[${index}]=${id}`);
      });
    }

    let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return Client.get(getApiUri(`v3/topic-tags${queryString}`));
  }

  // B2B APIs

  static async getB2BTopicPathwayCollections(includes = [], token = null) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(
      getApiUri(
        `/v4/b2b/topic-pathway-collections?${queryParams}&token=${token}`
      )
    );
  }

  static async getB2BTopicPathwayQuizFromShortCode(
    shortCode,
    includes = ['quiz'],
    token = null
  ) {
    return Client.get(
      getApiUri(
        `/v4/b2b/topic-pathway-quizs/shortcode/${shortCode}?${queryString.stringify(
          {
            includes,
            token
          }
        )}`
      )
    );
  }

  static async searchB2BTopicPathwayCollectionQuiz({
    searchText = '',
    topicPathwayCollectionIds = [],
    yearGroupId = null,
    subjectIds = [],
    sortFilter = null,
    includeNoInterventionTopics = true,
    skip = 0,
    take = 9999,
    token = null
  }) {
    let query = {
      'request.filter.searchText': searchText,
      'request.filter.subjectIds': subjectIds ? subjectIds : [],
      'request.filter.topicPathwayCollectionIds': topicPathwayCollectionIds,
      'request.filter.yearGroupId': yearGroupId,
      'request.filter.includeNoInterventionTopics': includeNoInterventionTopics,
      'request.skip': skip,
      'request.take': take,
      token: token
    };

    if (sortFilter) {
      query = {
        ...query,
        'request.sort[0].FieldName': sortFilter,
        'request.sort[0].Direction': 'Asc' // Our current UI doesn't seem to do sort directions?}
      };

      if (sortFilter === 'Level') {
        // For sorting with Level, we want to subsequently sort the result by they level sequence
        query = {
          ...query,
          'request.sort[1].FieldName': 'Sequence',
          'request.sort[1].Direction': 'Asc' // Our current UI doesn't seem to do sort directions?}
        };
      }
    }

    const params = queryString.stringify(query);

    return Client.get(
      getApiUri(`/v4/b2b/topic-pathway-collections/search?${params}`)
    );
  }

  static async getExperiment(experimentId, targetId) {
    return Client.get(
      getFunctionsApiUri(`v3/experiments/${experimentId}/targets/${targetId}`)
    );
  }

  static async postExperiment(experimentId, targetId, pageId, payload) {
    return Client.post(
      getFunctionsApiUri(
        `v3/experiments/${experimentId}/targets/${targetId}/pages/${pageId}`
      ),
      payload
    );
  }

  /*
    Lesson API
   */
  /**
   * Get a lesson
   * @param {number} userId (required) The userId of this user.
   * @param {number} lessonId (required) The lessonId of the lesson this request is after.
   */
  static async getLesson(userId, lessonId) {
    return Client.get(getApiUri(`v3/users/${userId}/lessons/${lessonId}`));
  }

  /**
   * Get lessons belong to the current user, ordered by date DESC
   * @param {number} userId (required) The userId of this user.
   * @param {object} params
   * @param {number} [params.skip=0] Number of lessons to skip
   * @param {number} [params.take=10] Number of lessons to take
   * @param {?('active'|'upcoming'|'done')} params.status The status of the lessons to get. `active` maps to `Ready`, `In Progress`, `Overdue`
   * @param {?number} params.quizId Filter to lessons for this quizId
   * @param {?number} params.questionId Filter to lessons for this questionId
   */
  static async getLessons(
    userId,
    params = {
      skip: 0,
      take: 10,
      status: undefined,
      quizId: undefined,
      questionId: undefined
    }
  ) {
    return Client.get(getApiUri(`v3/users/${userId}/lessons`), {
      'request.skip': params.skip,
      'request.take': params.take,
      'request.filter.status': params.status,
      'request.filter.quizId': params.quizId,
      'request.filter.questionId': params.questionId
    });
  }

  /**
   * Updates a lesson with new information
   * @param {number} userId The userId
   * @param {number} lessonId The Id of the lesson to update
   * @param {object} lessonUpdateDTO
   * @param {string} lessonUpdateDTO.flowSessionId Id of the flow session that was created for this lesson
   * @param {date} lessonUpdateDTO.date Date of this lesson is for.
   */
  static async updateLesson(userId, lessonId, lessonUpdateDTO) {
    return Client.put(
      getApiUri(`v3/users/${userId}/lessons/${lessonId}`),
      lessonUpdateDTO
    );
  }

  /**
   * Get a flow for a question and construct
   * @param {number} questionId (required)
   * @param {number} constructId Use -1 if you don't know
   */
  static async getFlow(questionId, constructId = -1) {
    return Client.get(
      getFunctionsApiUri(
        `v3/questions/${questionId}/construct/${constructId}/flow`
      )
    );
  }

  /**
   * Get a flow session for use with the tutoring bots
   * @param {number} userId (required) The userID of the flow this user is for
   * @param {string} flowSessionId Flow session (usually from the lesson)
   */
  static async getFlowSession(userId, flowSessionId) {
    return Client.get(
      getFunctionsApiUri(
        `v3/users/${userId}/question-construct-flow-sessions/${flowSessionId}`
      )
    );
  }

  /**
   * Create (or retrieve) a flow session for use with the tutoring bots
   * @param {number} userId (required) The userID of the flow this user is for
   * @param {string} userToken (required) The userID of the flow this user is for
   * @param {number} flowId (required) The id to use for the flow
   * @param {string} flowVersionId Version of the flow.
   */
  static async createFlowSession(userId, questionId, constructId = -1) {
    return Client.post(
      getFunctionsApiUri(
        `v3/users/${userId}/questions/${questionId}/constructs/${constructId}/question-construct-flow-sessions`
      )
    );
  }

  /* User State */

  static async getUserState(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/state`));
  }

  static async updateUserState(userId, partialUserState) {
    return Client.put(getApiUri(`v3/users/${userId}/state`), {
      stateValues: partialUserState
    });
  }

  static parseSort(sort) {
    let params = {};
    if (Object.prototype.toString.call(sort) === '[object Array]') {
      sort.forEach((s, i) => {
        params[`request.sort[${i}].FieldName`] = s.fieldName;
        params[`request.sort[${i}].Direction`] = s.direction;
      });
    }
    return params;
  }

  /* Hybrid Schools */
  static async getHybridSchool(schoolId) {
    return Client.get(getApiUri(`v3/hybrid-schools/${schoolId}`));
  }

  static async getAndOrEnrollHybridSchool(schoolId) {
    return Client.get(getApiUri(`v3/hybrid-schools/${schoolId}/enroll`));
  }

  /* Demo Groups */
  static async getInvitableParentCount(teacherId, groupId) {
    return Client.get(
      getApiUri(
        `v4/teachers/${teacherId}/groups/${groupId}/parents/invitable-count`
      )
    );
  }

  static async getParentInvitations(
    teacherId,
    groupId,
    request = {
      take: 30,
      skip: 0
    }
  ) {
    return Client.get(
      getApiUri(
        `/v4/teachers/${teacherId}/groups/${groupId}/parents/invitations`
      ),
      {
        'request.skip': request.skip,
        'request.take': request.take
      }
    );
  }

  static async sendParentInvitation(teacherId, body = {}) {
    return Client.post(
      getApiUri(`/v4/teachers/${teacherId}/parents/invitations`),
      body
    );
  }

  /* Heartbeats */
  static async heartbeat(userId, body = { source: 'EediTeacher' }) {
    return Client.post(
      getFunctionsApiUri(`v3/users/${userId}/heartbeat`),
      body
    );
  }

  /* Eedi Home */
  static async getEediHomeYearGroups() {
    return Client.get(getApiUri(`v3/year-groups/eedi-home`));
  }

  static async getYearGroups(countryCode) {
    return Client.get(
      getApiUri(`v3/countries/${countryCode}/country-year-groups`)
    );
  }

  /* SignalR Hub */
  static async getSignalRConnectionInfo(userId, hubName, userRole) {
    return Client.get(
      getFunctionsApiUri(`/negotiate?hubName=${hubName}`),
      {},
      {
        headers: {
          'x-ms-signalr-userid': userId,
          userRole: userRole || 'Student'
        }
      }
    );
  }

  /* Flow API */

  /**
   * Fetches the SignalR connection info.
   * @param {*} userId
   * @param {*} hubName
   * @param {*} userRole
   * @returns
   */
  static async getLessonHubSignalRConnectionInfo(userId, hubName, userRole) {
    return Client.get(
      getFlowApiUri(`/${hubName}/negotiate`),
      {},
      {
        headers: {
          'x-ms-signalr-userid': userId,
          userRole: userRole || 'Student'
        }
      }
    );
  }

  static async assistantFeedback({
    comments,
    isPositiveFeedback,
    feedbackTableName,
    feedbackPartitionKey,
    feedbackRowKey
  }) {
    return Client.post(getFlowApiUri(`v1/teacher-assistant/feedback`), {
      comments: comments || '',
      isPositiveFeedback,
      feedbackTableName,
      feedbackPartitionKey,
      feedbackRowKey
    });
  }

  static async generateDynamicPDF(instruction) {
    if (!instruction) throw new Error('Instruction is required');
    return Client.post(getFlowApiUri(`v1/pdf-generator/gpt-dynamic`), {
      instruction
    });
  }

  static async generateCSVSummary(instruction) {
    if (!instruction) throw new Error('Instruction is required');
    return Client.post(getFlowApiUri(`v1/teacher-assistant/csv-summarizer`), {
      instruction
    });
  }

  static async askTeacherAssistant(instruction) {
    if (!instruction) throw new Error('Instruction is required');
    return Client.post(getFlowApiUri(`v1/teacher-assistant`), {
      instruction
    });
  }

  static async generateGenericParentInvitePDF(groupId) {
    if (!groupId) throw new Error('groupId is required');
    return Client.post(
      getFlowApiUri(`v1/groups/${groupId}/generic-parent-invitations`)
    );
  }

  static async generateParentInvitePDF(groupId, studentId = null) {
    if (!groupId) throw new Error('groupId is required');
    return Client.post(
      getFlowApiUri(`v1/groups/${groupId}/parent-invitations`),
      {
        studentId
      }
    );
  }

  static async generateAssignmentStudentReport(instruction) {
    if (!instruction) throw new Error('Instruction is required');
    return Client.post(
      getFlowApiUri(`v1/teacher-assistant/assignment-student-report`),
      {
        instruction
      }
    );
  }

  /** Leagues */
  static async getSchoolLeagueInsights(schoolId, type, geoFilterType) {
    const params = {};
    if (geoFilterType) {
      params.geoFilterType = geoFilterType;
    }
    if (type) {
      params.type = type;
    }
    return Client.get(
      getApiUri(`/v3/schools/${schoolId}/schools-leaderboard-insight`),
      params
    );
  }

  static async getClassLeagueInsights({
    schoolId,
    dateFrom,
    dateTo,
    skip,
    take,
    yearGroupIds = []
  }) {
    let queryParams = [];

    if (dateFrom) {
      queryParams.push(`filter.dateFrom=${encodeURIComponent(dateFrom)}`);
    }

    if (dateTo) {
      queryParams.push(`filter.dateTo=${encodeURIComponent(dateTo)}`);
    }

    if (skip !== undefined) {
      queryParams.push(`skip=${encodeURIComponent(skip)}`);
    }

    if (take !== undefined) {
      queryParams.push(`take=${encodeURIComponent(take)}`);
    }

    if (yearGroupIds.length > 0) {
      queryParams.push(
        `filter.yearGroupIds=${encodeURIComponent(yearGroupIds.join(','))}`
      );
    }

    const queryString = queryParams.join('&');
    return Client.get(
      getApiUri(
        `/v3/schools/${schoolId}/groups-leaderboard-insight?${queryString}`
      )
    );
  }

  /** Admin */
  static async getDepartments(schoolId) {
    return Client.get(getApiUri(`v3/schools/${schoolId}/departments`));
  }

  static async getSchoolYearGroups(schoolId) {
    return Client.get(getApiUri(`v3/schools/${schoolId}/year-groups`));
  }

  static async getPlan(teacherId, groupId, page, limit) {
    return Client.get(
      getApiUri(`v5/teachers/${teacherId}/groups/${groupId}/homework-insights`),
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.sort[0].FieldName': 'WeekStart',
        'request.sort[0].Direction': 'Asc'
      }
    );
  }

  static async insertWeek(teacherId, groupId, weekNumber) {
    return Client.post(
      getApiUri(
        `v5/teachers/${teacherId}/groups/${groupId}/weeks/${weekNumber}`
      )
    );
  }

  static async toggleWeek(teacherId, groupId, weekNumber) {
    return Client.put(
      getApiUri(
        `v5/teachers/${teacherId}/groups/${groupId}/weeks/${weekNumber}`
      )
    );
  }

  static async deleteWeek(teacherId, groupId, weekNumber) {
    return Client.delete(
      getApiUri(
        `v5/teachers/${teacherId}/groups/${groupId}/weeks/${weekNumber}`
      )
    );
  }

  static async getGroupWeeklySummary(
    teacherId,
    groupId,
    fromDate,
    toDate,
    page,
    limit
  ) {
    return Client.get(
      getApiUri(`v5/teachers/${teacherId}/groups/${groupId}/weekly-summary`),
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.fromDate': fromDate,
        'request.filter.toDate': toDate,
        'request.sort[0].FieldName': 'WeekStart',
        'request.sort[0].Direction': 'Desc'
      }
    );
  }

  static async getCompletionSummary(teacherId) {
    return Client.get(getApiUri(`v5/teachers/${teacherId}/completion-summary`));
  }

  static async getGroupCompletionSummary(schoolId, groupId) {
    return Client.get(
      getApiUri(`v5/schools/${schoolId}/groups/${groupId}/completion-summary`)
    );
  }

  /** Prerequisite quizzes */
  static async GetGenericPreRequisiteQuiz(quizId) {
    return Client.get(getApiUri(`/v3/quizzes/${quizId}/prerequisite-quiz`));
  }

  static async GetPreRequisiteScores(groupId, quizId, minAnswerCount = 10) {
    return Client.get(
      getApiUri(
        `/v3/groups/${groupId}/quizzes/${quizId}/predicted-scores?minAnswerCount=${minAnswerCount}`
      )
    );
  }

  static async getGroupWeeklyInsight(teacherId, groupId, weekNumber) {
    return Client.get(
      getApiUri(
        `v5/teachers/${teacherId}/groups/${groupId}/weekly-insight/${weekNumber}`
      )
    );
  }

  static async getStudentWeeklyInsight(
    teacherId,
    groupId,
    studentId,
    weekNumber
  ) {
    return Client.get(
      getApiUri(
        `v5/teachers/${teacherId}/groups/${groupId}/students/${studentId}/weekly-insight/${weekNumber}`
      )
    );
  }

  static async AssignPrequisiteQuiz(
    groupId,
    teacherId,
    topicPathwayQuizId,
    startDate,
    endDate,
    templateSlug
  ) {
    const body = {
      topicPathwayQuizId
    };

    if (startDate) {
      body.startDate = startDate;
    }

    if (endDate) {
      body.endDate = endDate;
    }

    if (templateSlug) {
      body.templateSlug = templateSlug;
    }

    return Client.post(
      getApiUri(
        `/v3/teachers/${teacherId}/groups/${groupId}/prerequisite-assignment`
      ),
      body
    );
  }

  static async createFlowGeneratorSessionForChallenge(
    userId,
    topicPathwayQuizId,
    templateSlug = null,
    isFetchExistingSession = true,
    quizSessionId = null,
    token = null
  ) {
    let queryParams = quizSessionId ? `?quizSessionId=${quizSessionId}` : '';
    return Client.post(
      getFlowApiUri(
        `/v1/users/${userId}/topic-pathway-quizs/${topicPathwayQuizId}/flow-generator-sessions/challenge-worksheet${queryParams}`
      ),
      {
        templateSlug,
        isFetchExistingSession
      },
      {
        token
      }
    );
  }

  static async createFlowGeneratorSessionForChatWithTutor(
    userId,
    templateSlug = 'ChatWithTutor_v1',
    token = null
  ) {
    return Client.post(
      getFlowApiUri(
        `/v1/users/${userId}/chat-with-tutor/flow-generator-sessions`
      ),
      {
        templateSlug
      },
      {
        token
      }
    );
  }

  static async createFlowGeneratorSessionForOnDemand(
    userId,
    templateSlug = 'ChatWithTutor_v1',
    token = null
  ) {
    return Client.post(
      getFlowApiUri(`/v1/users/${userId}/on-demand/flow-generator-sessions`),
      {
        templateSlug
      },
      {
        token
      }
    );
  }

  static async createFlowGeneratorSessionForTopicPathwayQuizQuestion(
    userId,
    topicPathwayQuizId,
    sequence,
    quizSessionId = null,
    templateSlug = 'TopicQuizConstructLesson_2022-06-09',
    isFetchExistingSession = true,
    token = null
  ) {
    let queryParams = quizSessionId ? `?quizSessionId=${quizSessionId}` : '';

    return Client.post(
      getFlowApiUri(
        `/v1/users/${userId}/topicPathwayQuizs/${topicPathwayQuizId}/sequence/${sequence}${queryParams}`
      ),
      {
        templateSlug,
        isFetchExistingSession
      },
      {
        token
      }
    );
  }

  // Question inference
  static async getQuizInferences({
    quizIds = [],
    topicPathwayCollectionId = null,
    topicTagIds = [],
    parentTopicTagIds = [],
    countryYearGroupIds = [],
    userIds = [],
    groupId = null
  }) {
    return Client.post(getApiUri(`/v3/quiz-inferences/generate`), {
      quizIds,
      topicPathwayCollectionId,
      topicTagIds,
      parentTopicTagIds,
      countryYearGroupIds,
      userIds,
      groupId
    });
  }

  static generateQuizInferencesCSV({ quizIds = [], userIds = [] }) {
    return Client.post(getApiUri(`v3/quiz-inferences/generate-csv`), {
      quizIds,
      userIds
    });
  }

  // Question inference reports
  static async getGroupInferenceInsights({
    schoolId,
    groupId,
    topicPathwayCollectionId = 4,
    countryCode = 'GB',
    userIds = []
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/groups/${groupId}/inference-insights`),
      { topicPathwayCollectionId, countryCode, userIds }
    );
  }

  static async getGroupGrowthReportPDFs({
    schoolId,
    groupId,
    topicPathwayCollectionId = 4,
    countryCode = 'GB',
    userIds = []
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/groups/${groupId}/growth-reports-pdf`),
      { topicPathwayCollectionId, countryCode, userIds }
    );
  }

  static async getGroupGrowthHistoryReportCSV({
    schoolId,
    groupId,
    topicPathwayCollectionId = 4,
    countryCode = 'GB'
  }) {
    return Client.post(
      getApiUri(
        `v3/schools/${schoolId}/groups/${groupId}/growth-report-history-csv`
      ),
      { topicPathwayCollectionId, countryCode }
    );
  }

  static async getSchoolGrowthHistoryReportCSV({
    schoolId,
    topicPathwayCollectionId = 4,
    countryYearGroupId,
    countryCode = 'GB'
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/growth-report-history-csv`),
      { topicPathwayCollectionId, countryCode, countryYearGroupId }
    );
  }

  static async getGroupGrowthReportCSV({
    schoolId,
    groupId,
    topicPathwayCollectionId = 4,
    countryCode = 'GB'
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/groups/${groupId}/growth-report-csv`),
      { topicPathwayCollectionId, countryCode }
    );
  }

  static async getSchoolGrowthReportCSV({
    schoolId,
    topicPathwayCollectionId = 4,
    countryCode = 'GB',
    months = 1,
    month,
    year,
    countryYearGroupId
  }) {
    return Client.post(getApiUri(`v3/schools/${schoolId}/growth-report-csv`), {
      topicPathwayCollectionId,
      countryCode,
      months,
      month,
      year,
      countryYearGroupId
    });
  }

  static async getGroupDiagnosticQuestionReportCSV({
    schoolId,
    groupId,
    month,
    months = 1,
    year
  }) {
    return Client.post(
      getApiUri(
        `v3/schools/${schoolId}/groups/${groupId}/diagnostic-question-report-csv`
      ),
      { month, months, year },
      {
        // 5 minute timeout
        timeout: 300000
      }
    );
  }

  static async getSchoolDiagnosticQuestionReportCSV({
    schoolId,
    months = 1,
    month,
    year,
    countryYearGroupId
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/diagnostic-question-report-csv`),
      { months, month, year, countryYearGroupId },
      {
        // 6 minute timeout
        timeout: 360000
      }
    );
  }

  static async getGroupLessonProgressReportCSV({
    schoolId,
    groupId,
    month,
    months = 1,
    year
  }) {
    return Client.post(
      getApiUri(
        `v3/schools/${schoolId}/groups/${groupId}/lesson-progress-report-csv`
      ),
      { month, months, year },
      {
        // 5 minute timeout
        timeout: 300000
      }
    );
  }

  static async getSchoolLessonProgressReportCSV({
    schoolId,
    months = 1,
    month,
    year,
    countryYearGroupId
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/lesson-progress-report-csv`),
      { months, month, year, countryYearGroupId },
      {
        // 6 minute timeout
        timeout: 360000
      }
    );
  }

  static async getGroupTransactionInsightsCSVReports({ schoolId, groupId }) {
    return Client.post(
      getApiUri(
        `v3/schools/${schoolId}/groups/${groupId}/transaction-insights-csv`
      )
    );
  }

  static async getSchoolTransactionInsightsCSVReports({
    schoolId,
    months = 1,
    month,
    year,
    countryYearGroupId
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/transaction-insights-csv`),
      { months, month, year, countryYearGroupId },
      {
        // 6 minute timeout
        timeout: 360000
      }
    );
  }

  static async getGroupRoutineInsightsCSVReports({ schoolId, groupId }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/groups/${groupId}/routine-insights-csv`)
    );
  }

  static async getSchoolRoutineInsightsCSVReports({
    schoolId,
    months = 1,
    month,
    year,
    countryYearGroupId
  }) {
    return Client.post(
      getApiUri(`v3/schools/${schoolId}/routine-insights-csv`),
      { months, month, year, countryYearGroupId },
      {
        // 6 minute timeout
        timeout: 360000
      }
    );
  }

  // Motivation service
  static async updateMotivationalFeatures(schoolId, features = []) {
    let queryParams = queryString.stringify({ features });
    return Client.put(
      getApiUri(`v3/schools/${schoolId}/motivational-features?${queryParams}`)
    );
  }

  // MIS Sync
  static async getMISSyncDetails(schoolId) {
    return Client.get(getApiUri(`/v3/schools/${schoolId}/mis-sync`));
  }

  static async getCleverGroupSyncDetails({
    schoolId,
    groupMISId = null,
    departmentName = null,
    groupCode = null,
    skip = 0,
    take = 20
  }) {
    const query = {
      'request.skip': skip,
      'request.take': take
    };

    if (groupMISId) {
      query['request.filter.groupMISId'] = groupMISId;
    }

    if (departmentName) {
      query['request.filter.departmentName'] = departmentName;
    }

    if (groupCode) {
      query['request.filter.groupCode'] = groupCode;
    }
    return Client.get(
      getApiUri(`/v3/schools/${schoolId}/clever/sections`),
      query,
      {
        // 5 minute timeout
        timeout: 300000
      }
    );
  }

  static async getGoogleGroupSyncDetails({
    schoolId,
    groupMISId = null,
    departmentName = null,
    groupCode = null,
    skip = 0,
    take = 20
  }) {
    const query = {
      'request.skip': skip,
      'request.take': take
    };

    if (groupMISId) {
      query['request.filter.groupMISId'] = groupMISId;
    }

    if (departmentName) {
      query['request.filter.departmentName'] = departmentName;
    }

    if (groupCode) {
      query['request.filter.groupCode'] = groupCode;
    }
    return Client.get(
      getApiUri(`/v3/schools/${schoolId}/google-classroom/courses`),
      query,
      {
        // 5 minute timeout
        timeout: 300000
      }
    );
  }

  static async syncGoogleGroup(
    schoolId,
    groupMISIds = [],
    studentMISIds = [],
    updateType = 'Merge',
    yearGroupId = null,
    departmentId = null
  ) {
    return Client.post(
      getApiUri(`/v3/schools/${schoolId}/google-classroom/courses/sync`),
      {
        studentMISIds,
        updateType,
        yearGroupId,
        groupMISIds,
        departmentId
      },
      {
        // 30 minute timeout
        timeout: 1800000
      }
    );
  }

  static async syncCleverGroup(
    schoolId,
    groupMISIds = [],
    studentMISIds = [],
    updateType = 'Merge',
    yearGroupId = null,
    departmentId = null
  ) {
    return Client.post(
      getApiUri(`/v3/schools/${schoolId}/clever/sections/sync`),
      {
        studentMISIds,
        updateType,
        yearGroupId,
        groupMISIds,
        departmentId
      },
      {
        // 30 minute timeout
        timeout: 1800000
      }
    );
  }

  static async getWondeGroupSyncDetails({
    schoolId,
    groupMISId = null,
    departmentName = null,
    groupCode = null,
    skip = 0,
    take = 20
  }) {
    const query = {
      'request.skip': skip,
      'request.take': take
    };

    if (groupMISId) {
      query['request.filter.groupMISId'] = groupMISId;
    }

    if (departmentName) {
      query['request.filter.departmentName'] = departmentName;
    }

    if (groupCode) {
      query['request.filter.groupCode'] = groupCode;
    }

    return Client.get(
      getApiUri(`/v3/schools/${schoolId}/wonde/classes`),
      query,
      {
        // 5 minute timeout
        timeout: 300000
      }
    );
  }

  static async syncWondeGroup(
    schoolId,
    groupMISIds = [],
    studentMISIds = [],
    updateType = 'Merge',
    yearGroupId = null,
    departmentId = null
  ) {
    return Client.post(
      getApiUri(`/v3/schools/${schoolId}/wonde/classes/sync`),
      {
        studentMISIds,
        updateType,
        yearGroupId,
        departmentId,
        groupMISIds
      },
      {
        // 30 minute timeout
        timeout: 1800000
      }
    );
  }
}

export default Api;
